import Vue from 'vue'
import * as moment from "moment";
import axios from '../middleware/axios'
import idbs from "../api/offline/indexedDbService"
import uuidService from "../api/offline/uuidService";
export const state = () => ({
    loading: false,
    busy: false,
    collection: null,
    filteredCollection: null,
    instance: null,
    collectionMeta: null,
    isBeingCloned: false
})

export const getters = {

    getCollection: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( model => ( model.trashed !== true ) )
    },
    getCollectionWhereNegative: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            
            return null
        }
       // return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.answer_rating === "non-compliant" && action.is_escalated === false ))
       return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && (action.answer_rating === "non-compliant" ||(action.answer_rating === "no" && action.evaluation_type_code === "FRA")) && action.is_escalated === false ))
    },
    getCollectionWherePositive: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && (action.answer_rating === "compliant" ||(action.answer_rating === "yes" && action.evaluation_type_code === "FRA"))&& action.is_escalated === false ))
    },
    getCollectionWhereClosed: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
       return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === true && action.evaluation_type_code !== "WRA" ))
    },
    getCollectionWhereEscalated: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_escalated === true ))
    },
    getPaginationOptions: (state) => {

        if ( state.collectionMeta === null || state.collectionMeta.length === 0 ){
            return null
        }
        return ( "pagination" in state.collectionMeta ) ? state.collectionMeta.pagination : null
    },
    getInstance: (state) => {
        return state.instance
    },
    getIsLoading: (state) => {
        return state.loading
    },
    getCollectionWhereInProgress: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === true && action.evaluation_type_code !== "WRA" ))
    },
    getCollectionWhereNotStarted: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false  && (action.due_at === null || (action.due_at !== null && moment(action.due_at).isAfter(now))) && action.evaluation_type_code !== "WRA"))
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false  && action.evaluation_type_code !== "WRA"))
    },
    getCollectionWhereOverDue: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        const now = moment();
        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false && moment(action.due_at).isSameOrBefore(now) && action.evaluation_type_code !== "WRA" ))
        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false && moment(action.due_at).isBefore(now) && action.evaluation_type_code !== "WRA" ))
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && moment(action.due_at).isBefore(now) && action.evaluation_type_code !== "WRA" ))
    },
    getCollectionWhereAllActive: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( action => ( action.trashed !== true && action.evaluation_type_code !== "WRA" ))
    },
    getCollectionByEvaluation: (state)  => (evaluation) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }
        return [...state.collection].filter( model => ( model.trashed !== true  && model.evaluation_uuid === evaluation ) )
    },
    getCollectionWhereCritical: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false && action.evaluation_type_code === "FRA" && (action.risk_rating === "Critical" || action.due_by === "Immediate" || action.due_by === "24 hours" || action.due_by === "48 hours")))
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.evaluation_type_code === "FRA" && action.action_risk_rating === "Critical"))
    },
    getCollectionWhereHigh: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false && action.evaluation_type_code === "FRA"  && (action.risk_rating === "High" || action.due_by === "1 week")))
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.evaluation_type_code === "FRA"  && action.action_risk_rating === "High"))
    },
    getCollectionWhereMedium: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false && action.evaluation_type_code === "FRA" && (action.risk_rating === "Medium" || action.due_by === "1 month")))
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.evaluation_type_code === "FRA" && action.action_risk_rating === "Medium"))
    },    
    getCollectionWhereLow: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false && action.evaluation_type_code === "FRA" && (action.risk_rating === "Low" || action.due_by === "3 months")))
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.evaluation_type_code === "FRA" && action.action_risk_rating === "Low"))
    },        
    getCollectionWhereUnspecified: (state) => {
        if (state.collection === null || state.collection.length === 0) {
            return null
        }

        //  return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.is_in_progress === false && action.evaluation_type_code === "FRA"  && action.risk_rating === "" && action.due_by === ""))
        return [...state.collection].filter( action => ( action.trashed !== true && action.is_closed === false && action.evaluation_type_code === "FRA"  && (action.action_risk_rating === "" || action.action_risk_rating === "Unspecified" || action.action_risk_rating === null)))
    },
}

export const mutations = {

    setLoading( state, isLoading ){
        state.loading = isLoading === true
    },
    setBusy( state, isBusy )
    {
        state.busy = isBusy === true
    },
    setCollection: ( state, data ) => {
        state.collection = data
    },

    mergeCollection: ( state, data ) => {
        state.collection = [...state.collection, ...data]
    },

    resetCollection: ( state, data ) => {
        state.collection = data
    },

    setCollectionMeta: ( state, data ) => {
        state.collectionMeta = data
    },

    setFilteredCollection: ( state, data ) => {        
        state.filteredCollection = data
    },

    addToCollection: ( state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            // console.log("adding new model "+ model.uuid + " to action store")
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
        }        

    },

    upsertToCollection: (state, model ) => {
        if ( state.collection === null )
        {
            state.collection = []
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            // console.log("pushing new action model: " + model.uuid )
            state.collection.push(model)
        }else{
            Vue.set(state.collection, index, model )
            // console.log("updating old model: " + model.uuid )
        }
    },

    setInstance: ( state, model ) => {
        return ( state.instance = model )
    },
    clearInstance: ( state ) => {
        state.instance = null
    },
    setCollectionFilteredByPromptUuid: ( state, evaluationPromptUuid) => {
        state.filteredCollection = []

        if (state.collection === null || state.collection.length === 0) {
            return
        }

        for ( const model of state.collection ){
            if ( model.evaluation_prompt_uuid === evaluationPromptUuid )
            {
                state.filteredCollection.push( model )
            }
        }
    },
    updateInCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }

        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)

        if ( index === -1 ){
            return
        }

        // console.log("updating answer collection index for: " + index )
        Vue.set(state.collection, index, model )

        // also update any filtered collection

        if ( state.filteredCollection && state.filteredCollection.length > 0 )
        {
            const index = state.filteredCollection.findIndex((entry) => entry.uuid === model.uuid)
            //  console.log("state.filteredCollection && state.filteredCollection.length > 0 - index - answer actions", index)
            if ( index !== -1 ){
                // state.filteredCollection[index] = model
                Vue.set(state.filteredCollection, index, model)
            }
        }
    },

    removeFromCollection: ( state, model ) =>
    {
        if ( ! state.collection || state.collection.length < 1 )
        {
            return
        }
        const index = state.collection.findIndex((entry) => entry.uuid === model.uuid)
        if ( index === -1 ){
            return
        }
        state.collection.splice(index,1)
    },

}

export const actions = {

    clearStore( {commit} ){
        commit("setInstance", null)
        commit("setCollection", null)
        commit("setFilteredCollection", null)
        commit("setCollectionMeta", null)
    },
    setInstanceFromCollectionByUuid( { commit, state },{uuid} ){

        if ( state.collection && state.collection.length > 0 )
        {
            const index = state.collection.findIndex((entry) => entry.uuid === uuid )

            if ( index !== -1 ){
                commit("setInstance", state.collection[index] )
            }
        }

    },
    updateCollectionState({commit}, models )
    {
        for ( const model of models ){

            commit('upsertToCollection', model )
        }
    },
    clearInstance( { commit } ){
        commit("clearInstance")
    },
    filterCollectionByEvaluationPrompt( {commit}, {evaluationPromptUuid} )
    {
      commit("setCollectionFilteredByPromptUuid", evaluationPromptUuid)
    },
    async collection( { commit, rootState }, filters={}) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)
        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('actions','evaluation_uuid', filters.evaluation ).then( (response) => {

                offlineCollection = response

                commit('setCollection', response )

                commit('setLoading', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/actions',{
                    params: filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    const test = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){
                        if ( offlineCollection !== null )
                        {
                            test.push(offlineCollection)
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{

                                commit('addToCollection',offlineCollection[index] )

                                if (!offlineCollection[index].hasOwnProperty('copied_action_id') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('is_closed_through_latest_evaluation') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('evaluation_type_prompt_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('answer_description') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('evaluation_category_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }

                        if ( updatedOfflineCollection.length > 0 ){
                            idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                            idbs.saveToOfflineStorage('actions', updatedOfflineCollection )
                        }

                    }
                    // const deleteOperations = []
                    // for(const model of offlineCollection){
                    //     const index = test.findIndex((entry) => entry.uuid === model.uuid)
                    //     if(index === -1){
                    //         deleteOperations.push(
                    //             idbs.deleteFromOfflineStore('actions', model.uuid)
                    //             .then(async () => {
                    //                 const response = await idbs.getAllFromOffline('actions', 'evaluation_uuid', filters.evaluation);
                    //                 offlineCollection = response;
                    //             })
                    //             .catch((error) => {
                    //                 // Handle error if required
                    //                 console.error('Deletion error:', error)
                    //             })
                    //         )
                            
                    //     }
                        

                    // }
                    // Promise.all(deleteOperations)
                    commit('setCollection', offlineCollection)
                    
                    idbs.saveToOfflineStorage('actions', response.data.data)
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    
    // async collection({ commit, rootState }, filters = {}) {
    //     commit('setLoading', true);
    //     commit('setCollectionMeta', null);
    //     let offlineCollection = null;
    
    //     try {
            
    //         const offlineResponse = await idbs.getAllFromOffline('actions', 'evaluation_uuid', filters.evaluation);
    //         offlineCollection = offlineResponse;
    
    //         commit('setCollection', offlineResponse);
    //         commit('setLoading', false);
    //     } catch (error) {
            
    //     }
    
    //     try {
           
    //         const apiResponse = await axios.get(process.env.apiUrl + '/actions', {
    //             params: filters,
    //             headers: {
    //                 Authorization: 'Bearer ' + rootState.auth.authToken,
    //                 Accept: 'application/json'
    //             }
    //         });
    
    //         const updatedOfflineCollection = [];
    
    //         if ('meta' in apiResponse.data) {
    //             commit('setCollectionMeta', apiResponse.data.meta);
    //         }
    
    //         for (const model of apiResponse.data.data) {
                
    //             if ( offlineCollection !== null )
    //             {
    //                 const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid);
        
    //                 if (index === -1 || moment(offlineCollection[index].updated_at).isBefore(model.updated_at)) {
    //                     commit('addToCollection', model);
    //                     updatedOfflineCollection.push(model);
    //                 } else {
    //                     commit('addToCollection', offlineCollection[index]);
        
    //                     if (
    //                         !offlineCollection[index].hasOwnProperty('copied_action_id') ||
    //                         !offlineCollection[index].hasOwnProperty('is_closed_through_latest_evaluation') ||
    //                         !offlineCollection[index].hasOwnProperty('evaluation_type_prompt_uuid') ||
    //                         !offlineCollection[index].hasOwnProperty('answer_description')
    //                     ) {
    //                         updatedOfflineCollection.push(model);
    //                     }
    //                 }
    //             }
    //             else{
    //                 commit('addToCollection', model )
    //                 updatedOfflineCollection.push( model )
    //             }
               
    //         }
    
    //         if (updatedOfflineCollection.length > 0) {
                
    //             await idbs.saveToOfflineStorage('answers', updatedOfflineCollection);
    //             await idbs.saveToOfflineStorage('actions', updatedOfflineCollection);
    //         }    

    //         if ( offlineCollection !== null )
    //         {
    //             const deleteOperations = [];
    
    //             for (const model of offlineCollection) {
    //                 const index = apiResponse.data.data.findIndex((entry) => entry.uuid === model.uuid);
    //                 if (index === -1) {
    //                     deleteOperations.push(
    //                         idbs.deleteFromOfflineStore('actions', model.uuid)
    //                             .then(async () => {
    //                                 //  const response = await idbs.getAllFromOffline('actions', 'evaluation_uuid', filters.evaluation);
    //                                 //  offlineCollection = response;
    //                             })
    //                             .catch((error) => {
                                   
    //                                 console.error('Deletion error:', error);
    //                             })
    //                     );
    //                 }
    //             }
        
    //             await Promise.all(deleteOperations);
                        
    //             //  commit('setCollection', offlineCollection);
    //         }
            
    //         await idbs.saveToOfflineStorage('actions', apiResponse.data.data);
    
    //         return apiResponse.data;

    //     } catch (error) {
           
    //         throw error;
    //     } finally {
    //         commit('setLoading', false);
    //     }
    // },
    
    async collectionBySite( { commit, rootState }, params) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)
        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('actions','site_uuid', params.site).then( (response) => {

                offlineCollection = response

                commit('setCollection', response )

                commit('setLoading', false)
            })

        }catch( error )
        {

        }
        try {

            await axios
                .get( process.env.apiUrl + '/actions',{
                    params: params.filters,
                    headers: {
                        timestamp: Date.now(),
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )                        
                    }                    

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            { 
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{
                                commit('addToCollection',offlineCollection[index] )                                
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }

                        if ( updatedOfflineCollection.length > 0 ){
                            idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        }

                    }
                    idbs.saveToOfflineStorage('actions', response.data.data)
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async instance( { commit, rootState }, { id, filters }) {

        commit('setLoading', true)

        try {

            await idbs.getFromOfflineByPrimaryKey('actions',id ).then( (response) => {
                commit('setInstance', response )
                commit('setLoading', false)
            })

        }catch ( e ){

        }

        try {

            await axios
                .get( process.env.apiUrl +`/actions/${id}`, {
                    params: filters,
                    headers: {
                        timestamp: Date.now(),
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                }).then(function( response ){
                    commit('setInstance', response.data.data )
                    idbs.saveToOfflineStorage('actions', [response.data.data])

                    return response.data.data

                }).catch( function( error ){
                    return error
                })

        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async store( { commit, rootState }, data ){

        commit('setBusy', true)

        try {

            data.uuid = uuidService.generate()
            data.offline_only = true

            if ( "evaluation" in data ){
                data.evaluation_uuid = data.evaluation
            }

            await idbs.saveToOfflineStorage('actions', [data]).then((response) => {
                commit('addToCollection', data )
                commit('setBusy', false)
            })

        }catch( error ){
            console.log("offline save error")
            console.log( error )
        }

        if ( data.local_only !== true ){
            try {

                await axios
                    .post( process.env.apiUrl +`/actions`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){
                        commit('addToCollection', response.data.data)
                        idbs.saveToOfflineStorage('actions', [response.data.data])

                        return response.data.data
                    })
                    .catch(function(error){
                        return error
                    })

            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }

    },

    async batchStore( { commit, rootState }, data ){

        commit('setBusy', true)

        try {

            if("newActions" in data)
            {

                //  console.log("newActions", JSON.stringify(data.newActions))

                for ( let i = 0; i < data.newActions.length; i++ ){

                    if ( data.newActions[i].uuid === undefined || data.newActions[i].uuid === null || data.newActions[i].uuid.length === 0)
                    {
                       // console.log("generating uuid for " + i)
                        data.newActions[i].uuid = uuidService.generate()
                    }    
    
                    data.newActions[i].offline_only = true
                    data.newActions[i].user_uuid = data.user_uuid
    
                    if ( "answer" in data ){
                        // console.log("set answer against action to " +  data.answer )
                        data.newActions[i].answer_uuid = data.answer
                    }
    
                    if ( "rating" in data ){
                        data.newActions[i].answer_rating = data.rating
                    }
    
                    // if ( data.actions[i].is_escalated === undefined ){
                    //     data.actions[i].is_escalated = ("is_escalated" in data ) ? data.is_escalated : false
                    // }
    
                    if ( "evaluation" in data ){
                        data.newActions[i].evaluation_uuid = data.evaluation
                    }
    
                }
    
                await idbs.saveToOfflineStorage('actions', data.newActions).then((response) => {
    
                    for ( const model of data.newActions ) {
                        commit('addToCollection', model )
                    }
    
                    commit('setBusy', false)
                })
            }
            else{
                for ( let i = 0; i < data.actions.length; i++ ){

                    if ( data.actions[i].uuid === undefined || data.actions[i].uuid === null || data.actions[i].uuid.length === 0)
                    {
                       // console.log("generating uuid for " + i)
                        data.actions[i].uuid = uuidService.generate()
                    }
    
    
                    data.actions[i].offline_only = true
                    data.actions[i].user_uuid = data.user_uuid
    
                    if ( "answer" in data ){
                        // console.log("set answer against action to " +  data.answer )
                        data.actions[i].answer_uuid = data.answer
                    }
    
                    if ( "rating" in data ){
                        data.actions[i].answer_rating = data.rating
                    }
    
                    // if ( data.actions[i].is_escalated === undefined ){
                    //     data.actions[i].is_escalated = ("is_escalated" in data ) ? data.is_escalated : false
                    // }
    
                    if ( "evaluation" in data ){
                        data.actions[i].evaluation_uuid = data.evaluation
                    }
    
                }
    
                await idbs.saveToOfflineStorage('actions', data.actions).then((response) => {
    
                    for ( const model of data.actions ) {
                        commit('addToCollection', model )
                    }
    
                    commit('setBusy', false)
                })
            }

            

        }catch( error ){
            console.log("offline save error")
            console.log( error )
        }

        if ( data.local_only !== true ){

            try {

                await axios
                    .post( process.env.apiUrl +`/actions/batch`,data,{
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function( response ){

                        for ( const model of response.data.data ){
                            commit('addToCollection', model )
                        }

                        idbs.saveToOfflineStorage('actions', response.data.data)

                        return response.data
                    })
                    .catch(function(error){
                        return error
                    })

            }catch ( e ){
                throw e
            }finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }

    },

    async batchUpsert( { commit, rootState }, data ){

        commit('setBusy', true)

        // console.log("batchStore action data:")
        // console.log( data )

        try {

            if("updatedActions" in data)
            {
                //  console.log("updatedActions", JSON.stringify(data.updatedActions))
                for ( let i = 0; i < data.updatedActions.length; i++ ){
                    
                    data.updatedActions[i].offline_only = true
    
                    if ( "answer" in data && data.updatedActions[i].answer_uuid === undefined ){
                        // console.log("set answer against action to " +  data.answer )
                        data.updatedActions[i].answer_uuid = data.answer
                    }
    
                    if ( "rating" in data ){
                        data.updatedActions[i].answer_rating = data.rating
                    }
    
                    // if ( "is_escalated" in data && data.actions[i].is_escalated === undefined ){
                    //     data.actions[i].is_escalated = data.is_escalated
                    // }
    
                    if ( "evaluation" in data ){
                        data.updatedActions[i].evaluation_uuid = data.evaluation
                    }

                    idbs.getFromOfflineByPrimaryKey("actions", data.updatedActions[i].uuid ).then( function( offlineAction )
                    {
                        idbs.updateOfflineStorage('actions', offlineAction, data.updatedActions[i]).then((response) => {

                            commit('updateInCollection', data.updatedActions[i] )
                            commit('setBusy', false)
                        })
                    })
    
                }
    
                /* await idbs.saveToOfflineStorage('actions', data.updatedActions).then((response) => {
    
                    for ( const model of data.updatedActions ) {
                        commit('upsertToCollection', model )
                    }
    
                    commit('setBusy', false)
                }) */
            }
            else
            {
                for ( let i = 0; i < data.actions.length; i++ ){

                    if ( data.actions[i].uuid === undefined || data.actions[i].uuid === null || data.actions[i].uuid.length === 0)
                    {
                        data.actions[i].uuid = uuidService.generate()
                    }
    
                    data.actions[i].offline_only = true
    
                    if ( "answer" in data && data.actions[i].answer_uuid === undefined ){
                        // console.log("set answer against action to " +  data.answer )
                        data.actions[i].answer_uuid = data.answer
                    }
    
                    if ( "rating" in data ){
                        data.actions[i].answer_rating = data.rating
                    }
    
                    // if ( "is_escalated" in data && data.actions[i].is_escalated === undefined ){
                    //     data.actions[i].is_escalated = data.is_escalated
                    // }
    
                    if ( "evaluation" in data ){
                        data.actions[i].evaluation_uuid = data.evaluation
                    }
    
                }
    
                await idbs.saveToOfflineStorage('actions', data.actions).then((response) => {
    
                    for ( const model of data.actions ) {
                        commit('upsertToCollection', model )
                    }
    
                    commit('setBusy', false)
                })
            }           
            

        }catch( error ){
            console.log("offline save error")
            console.log( error )
        }

        if ( data.local_only !== true ) {
            try {

                await axios
                    .put(process.env.apiUrl + `/actions/batch-upsert`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        // this queues changes in a job so no need to store this back to offline/state
                        return response.data
                    })
                    .catch(function (error) {
                        return error
                    })

            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }
    },

    async update( { commit, rootState }, data ){

        commit('setBusy', true)

        data.offline_only = true

        try {

            await idbs.saveToOfflineStorage('actions', data).then((response) => {
                
                commit('updateInCollection', data )
                commit('setBusy', false)
            })            

        }catch( error ){

        }
        
        if ( data.local_only !== true ) {

            try {

                await axios
                    .patch(process.env.apiUrl + `/actions/${data.uuid}`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('updateInCollection', response.data.data)
                        idbs.saveToOfflineStorage('actions', [response.data.data])
                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }
    },
    async updateMultiple( { commit, rootState }, data ){

        commit('setBusy', true)

        //  data.offline_only = true

        try {

            await idbs.getAllFromOffline('actions','site_uuid', data.site).then( (response) => {

                if(response !== null && response.length > 0)
                {

                    if(data.related_actions !== null && data.related_actions.length > 0)
                    {
                        for ( const model of data.related_actions )
                        {
                            data.uuid = model.uuid

                            commit('updateInCollection', data )
                        }
                    }
                }

                commit('setBusy', false)
                
            })            

        }catch( error ){

        }
        
        if ( data.local_only !== true ) {

            try {

                await axios
                    .put(process.env.apiUrl + `/actions/update-multiple`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {

                        /* for ( const model of response.data.data ){
                            commit('updateInCollection', model)
                            idbs.saveToOfflineStorage('actions', [model])
                        } */

                        for ( const model of response.data.data ){

                            idbs.getFromOfflineByPrimaryKey("actions", model.uuid ).then( function( offlineAction )
                            {
                                idbs.updateOfflineStorage('actions', offlineAction, model).then((response) => {

                                    commit('updateInCollection', model )
                                })
                            })

                        }

                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }
    },
    updateCollectionFromAnyOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('actions' ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){
                        axios.put( process.env.apiUrl + `/actions/batch-upsert`,{ actions: offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                            .then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })
                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
     updateCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('actions', 'evaluation_uuid', data.evaluation ).then( (response) => {

                    const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                    if ( offlineOnlyCollection.length > 0 ){
                        axios.put( process.env.apiUrl + `/actions/batch-upsert`,{ evaluation: data.evaluation, actions: offlineOnlyCollection },{
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                Accept: 'application/json'
                            }
                        })
                            .then(function( response ){

                                resolve( response.data )
                            })
                            .catch(function(error){
                                reject( error )
                            })
                    }else{
                        resolve({ message: "No offline only models to sync"})
                    }

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    async destroy( { commit, rootState }, data ){

        commit('setBusy', true)

        if ( data.force ) {
            try {
                await idbs.deleteFromOfflineStore('actions', data.uuid).then((response) => {
                    commit('removeFromCollection', data)
                    commit('setBusy', false)
                })

            } catch (error) {
                console.log("remove action from offline error " + error)
            }

        }else{
            idbs.getFromOfflineByPrimaryKey('actions', data.uuid).then((response) => {

                data.trashed = true
                data.offline_only = true
                data.updated_at = moment().format("YYYY-MM-DD HH:mm:ss")

                data = {...response, ...data }

                idbs.saveToOfflineStorage('actions', [data]).then((response) => {
                    commit('removeFromCollection', data )
                    commit('setBusy', false)
                })
            })
        }
        if ( data.local_only !== true ) {
            try {

                await axios
                    .delete(process.env.apiUrl + `/actions/${data.uuid}`, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {
                        commit('removeFromCollection', response.data.data)

                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }
        }else{
            return data
        }
    },
    async collectionBySiteAppend( { commit, rootState }, params) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)
        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('actions','site_uuid', params.site).then( (response) => {

                offlineCollection = response

                commit('mergeCollection', response )

                commit('setLoading', false)
            })

        }catch( error )
        {

        }
        try {

            await axios
                .get( process.env.apiUrl + '/actions',{
                    params: params.filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                commit('addToCollection', model )
                                updatedOfflineCollection.push( model )
                            }else{

                                commit('addToCollection',offlineCollection[index] )

                                if (!offlineCollection[index].hasOwnProperty('copied_action_id') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('is_closed_through_latest_evaluation') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('evaluation_type_prompt_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('answer_description') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                            }

                        }else{
                            commit('addToCollection', model )
                            updatedOfflineCollection.push( model )
                        }

                        if ( updatedOfflineCollection.length > 0 ){
                            idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        }

                    }
                    idbs.saveToOfflineStorage('actions', response.data.data)
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    async collectionBySiteAppendOpened( { commit, rootState }, params) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)
        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('actions','site_uuid', params.site).then( (response) => {             

                offlineCollection = response  
                
                for ( const model of response ){
                    commit('removeFromCollection', model)         
                }

                commit('mergeCollection', response.filter( action => ( action.is_closed === false)))

                /* if(Object.keys(params).some(key => key === 'report_type'))
                {

                    if(params.report_type === 'Inspections')
                    {
                        commit('mergeCollection', response.filter( action => ( action.is_closed === false && action.evaluation_type_code !== 'FRA' && action.evaluation_type_code !== 'WRA')))
                    }
                    else{
                        commit('mergeCollection', response.filter( action => ( action.is_closed === false && action.evaluation_type_code === 'FRA')))
                    }                    
                }                 

                if(Object.keys(params).some(key => key === 'action_status'))
                {

                    if(params.action_status === 'notstarted')
                    {
                        commit('mergeCollection', response.filter( action => ( action.is_closed === false && action.is_in_progress === false)))
                    }
                    else if(params.action_status === 'inprogress'){
                        commit('mergeCollection', response.filter( action => ( action.is_closed === false && action.is_in_progress === true)))
                    } 
                    else if (params.action_status === 'overdue') {
                        const now = moment();
                        commit('mergeCollection', response.filter(action => (moment(action.due_at).isBefore(now) && !action.is_closed)));
                      }
                    else if (params.action_status === 'closed') {
                        commit('mergeCollection', response.filter(action => (action.is_closed === true)));
                      }
                      else if (params.action_status === '') {
                        commit('mergeCollection', response.filter(action => (action.is_closed === true)));
                      }
                      else{
                        commit('mergeCollection', response);
                      }                   
                }
                
                if(Object.keys(params).some(key => key === 'report_type') === false && Object.keys(params).some(key => key === 'action_status') === false)
                {
                    commit('mergeCollection', response.filter(action => (action.is_closed === true)));
                } */

                commit('setLoading', false)
            })

        }catch( error )
        {

        }

        try {

            await axios
                .get( process.env.apiUrl + '/actions',{
                    params: params.filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                            {
                                const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)
    
                                if ( index === -1 )
                                {

                                    if(model.is_closed === false)
                                    {
                                        commit('addToCollection', model )
                                    }

                                    updatedOfflineCollection.push( model )
                                }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                                {
                                    if(model.is_closed === false)
                                    {
                                        commit('addToCollection', model )
                                    }

                                    updatedOfflineCollection.push( model )
                                }else if ( offlineCollection[index].is_legacy !==  model.is_legacy)
                                {
                                    if(model.is_closed === false)
                                    {
                                        commit('addToCollection', model )
                                    }

                                    updatedOfflineCollection.push( model )
                                }else if(offlineCollection[index].is_closed === false){
    
                                    commit('addToCollection',offlineCollection[index] )

                                    // commit('addToCollection',offlineCollection[index] )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('copied_action_id') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('is_closed_through_latest_evaluation') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('evaluation_type_prompt_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('answer_description') )
                                {
                                    updatedOfflineCollection.push( model )
                                }

    
                            }else{
                                
                                if(model.is_closed === false)
                                {
                                    commit('addToCollection', model )
                                }

                                updatedOfflineCollection.push( model )
                            }
    
                            //  if ( updatedOfflineCollection.length > 0 ){
                            //      idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                            //  }                      

                    }

                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        idbs.saveToOfflineStorage('actions', updatedOfflineCollection )
                    }
                    
                    idbs.saveToOfflineStorage('actions', response.data.data)
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

        //  if(navigator.onLine)
        //  {
            //  alert('online')
            
        //  }
        //  else{

            //  alert('offline')
                        
        //  }

    },
    async collectionBySiteOpened( { commit, rootState }, params) {

        commit('setLoading', true)
        commit('setCollectionMeta', null)
        let offlineCollection = null

        try {

            await idbs.getAllFromOffline('actions','site_uuid', params.site).then( (response) => {

                offlineCollection = response

                //  commit('setCollection', response )

                commit('setCollection', response.filter( action => ( action.is_closed === false)))

                /* if(Object.keys(params).some(key => key === 'report_type'))
                {

                    if(params.report_type === 'Inspections')
                    {
                        commit('setCollection', response.filter( action => ( action.evaluation_type_code !== 'FRA' && action.evaluation_type_code !== 'WRA')))
                    }
                    else{
                        commit('setCollection', response.filter( action => ( action.evaluation_type_code === 'FRA')))
                    }
                                        
                }
                
                if(Object.keys(params).some(key => key === 'action_status'))
                {
                    
                    if(params.action_status === 'notstarted')
                    {
                        commit('setCollection', response.filter( action => ( action.is_closed === false && action.is_in_progress === false)))
                    }
                    else if(params.action_status === 'inprogress'){
                        commit('setCollection', response.filter( action => ( action.is_closed === false && action.is_in_progress === true)))
                    } 
                    else if (params.action_status === 'overdue') {
                        const now = moment();
                        commit('setCollection', response.filter(action => (moment(action.due_at).isBefore(now) && !action.is_closed)));
                      }
                    else if (params.action_status === 'closed') {
                        commit('setCollection', response.filter(action => (action.is_closed === true)));
                      }
                      else if (params.action_status === '') {
                        commit('setCollection', response.filter(action => (action.is_closed === true)));
                      }
                      else{
                        commit('setCollection', response);
                      }
                                       
                }
                

                if(Object.keys(params).some(key => key === 'report_type') === false && Object.keys(params).some(key => key === 'action_status') === false)
                {
                    commit('setCollection', response);
                } */

                //  commit('setCollection', response )

                commit('setLoading', false)
            })
            

        }catch( error )
        {

        }
        try {

            await axios
                .get( process.env.apiUrl + '/actions',{
                    params: params.filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []
                    
                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )                        
                    }   
                     
                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                        {
                            const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)

                            if ( index === -1 )
                            {
                                if(model.is_closed === false)
                                {
                                    commit('addToCollection', model )
                                }

                                updatedOfflineCollection.push( model )
                            }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                            {
                                
                                if(model.is_closed === false)
                                {
                                    commit('addToCollection', model )
                                }

                                updatedOfflineCollection.push( model )

                            }else{

                                //  commit('addToCollection',offlineCollection[index] )

                                if(offlineCollection[index].is_closed === false)
                                {
                                    commit('addToCollection',offlineCollection[index] )
                                }
                                
                                if (!offlineCollection[index].hasOwnProperty('copied_action_id') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('is_closed_through_latest_evaluation') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('evaluation_type_prompt_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('answer_description') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                            }

                        }else{
                            
                            if(model.is_closed === false)
                            {
                                commit('addToCollection', model )
                            }

                            updatedOfflineCollection.push( model )
                        }

                        //  if ( updatedOfflineCollection.length > 0 ){
                        //     idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        //  }

                    }

                    /* if ( offlineCollection !== null)
                    {
                        for(const model of offlineCollection)
                        {
                            const onlineRecordIndex = response.data.data.findIndex((entry) => entry.uuid === model.uuid)

                            if(onlineRecordIndex === -1)
                            {                                
                                commit('removeFromCollection', model)
                            }
                        }

                    } */
                    
                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        idbs.saveToOfflineStorage('actions', updatedOfflineCollection )
                    }

                    idbs.saveToOfflineStorage('actions', response.data.data)
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    },
    trashCollectionByCategoryUuid( {commit}, {categoryUuid, evaluationUuid, trashedState } )
    {
        // commit("trashCollectionByCategoryUuid", { categoryUuid, trashedState } )
        // we also need to handle storing these changes offline
        try {
            idbs.getAllFromOffline('answers','evaluation_uuid', evaluationUuid ).then( (response) => {
                console.log('response',response)
                if ( response !== undefined ) {
         
                    const trashedOffline = []
                    for ( let i=0; i < response.length; i++ )
                    {
                        /* if ( response[i].answer_uuid){
                            trashedOffline.push({ ...response[i], ...{ "trashed": trashedState, "offline_only": true }})
                        } */

                        console.log('response[i]',response[i])
                        if ( response[i].evaluation_category_uuid === categoryUuid){
                            trashedOffline.push({ ...response[i], ...{ "trashed": trashedState, "offline_only": true }})
                        }
                    }
                    if ( trashedOffline.length > 0 ){
                        idbs.saveToOfflineStorage('actions', trashedOffline )
                    }
                }
            })
        }catch( error )
        {
        }
    },
    async updatePreviousOpenActions( { commit, rootState }, data ){

        commit('setBusy', true)

        data.offline_only = true

        try {

            await idbs.getAllFromOffline('actions','site_uuid', data.site).then( (response) => {

                if(response !== null && response.length > 0)
                {
                    const index = response.findIndex((entry) => entry.uuid === data.uuid)

                    if(index !== -1)
                    {
                        commit('updateInCollection', data )
                        commit('setBusy', false)
                    }                    
                
                    if(data.relatedOpenedActions !== null && data.relatedOpenedActions.length > 0)
                    {
                        for ( const model of data.relatedOpenedActions )
                        {
                            data.uuid = model.uuid

                            commit('updateInCollection', data )
                        }
                    }
                }

                commit('setBusy', false)
                
            })

        }catch( error ){

        }
        
        if ( data.local_only !== true ) {

            try {

                await axios
                    .put(process.env.apiUrl + `/actions/update-multiple`, data, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.authToken,
                            Accept: 'application/json'
                        }
                    })
                    .then(function (response) {

                        for ( const model of response.data.data ){
                            commit('updateInCollection', model)
                            idbs.saveToOfflineStorage('actions', [model])
                        }

                        return response.data.data
                    })
                    .catch(function (error) {
                        return error
                    })
            } catch (e) {
                throw e
            } finally {
                commit('setBusy', false)
            }

        }else{
            return data
        }
    },
    updatePreviousCloseoutCollectionFromOffline( { commit, rootState }, data ) {

        commit('setBusy', true)

        return new Promise( ( resolve, reject ) => {

            try {

                idbs.getAllFromOffline('actions', 'closeout_initiated_evaluation', data.evaluation ).then( (response) => {

                    if(response && response !== null)
                    {                        
                        const offlineOnlyCollection = [...response.filter(model => (model.offline_only === true))]

                        const evaluationUuids = [...new Set(offlineOnlyCollection.map(x => x.evaluation_uuid))]

                        if(evaluationUuids && evaluationUuids !== null)
                        {
                            for ( const evaluationUuid of evaluationUuids )
                            {
                                const offlineOnlyCollectionForEvaluation = [...response.filter(model => (model.evaluation_uuid === evaluationUuid))]

                                if(offlineOnlyCollectionForEvaluation && offlineOnlyCollectionForEvaluation !== null && offlineOnlyCollectionForEvaluation.length > 0)
                                {
                                    if ( offlineOnlyCollection.length > 0 ){
                                        axios.put( process.env.apiUrl + `/actions/batch-upsert`,{ evaluation: evaluationUuid, actions: offlineOnlyCollectionForEvaluation },{
                                            headers: {
                                                Authorization: 'Bearer ' + rootState.auth.authToken,
                                                Accept: 'application/json'
                                            }
                                        })
                                            .then(function( response ){
                
                                                resolve( response.data )
                                            })
                                            .catch(function(error){
                                                reject( error )
                                            })
                                    }else{
                                        resolve({ message: "No offline only models to sync"})
                                    }
                                }
                            }
                        }
                    }
                                        

                }).finally(()=> {
                    commit('setBusy', false)
                })

            }catch( error ){
                commit('setBusy', false)
                reject( error )
            }
        })

    },
    collectionBySiteAppendOpenedMultiple( { commit, rootState }, params) {

        console.log(params)
        commit('setLoading', true)
        commit('setCollectionMeta', null)
        const offlineCollection = null

        /* try {

            await idbs.getAllFromOfflineMultiple('actions','site_uuid', params.site).then( (response) => {             

                console.log(JSON.stringify(response))
                offlineCollection = response  
                
                for ( const model of response ){
                    commit('removeFromCollection', model)         
                }

                commit('mergeCollection', response.filter( action => ( action.is_closed === false)))

                commit('setLoading', false)
            })

        }catch( error )
        {

        } */

        try {

            axios
                .get( process.env.apiUrl + '/actions',{
                    params: params.filters,
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.authToken,
                        Accept: 'application/json'
                    }
                })
                .then( function( response ) {

                    const updatedOfflineCollection = []

                    if ('meta' in response.data ){
                        commit('setCollectionMeta', response.data.meta )
                    }

                    for ( const model of response.data.data ){

                        if ( offlineCollection !== null )
                            {
                                const index = offlineCollection.findIndex((entry) => entry.uuid === model.uuid)
    
                                if ( index === -1 )
                                {

                                    if(model.is_closed === false)
                                    {
                                        commit('addToCollection', model )
                                    }

                                    updatedOfflineCollection.push( model )
                                }else if ( moment(offlineCollection[index].updated_at ).isBefore( model.updated_at ) )
                                {
                                    if(model.is_closed === false)
                                    {
                                        commit('addToCollection', model )
                                    }

                                    updatedOfflineCollection.push( model )
                                }else if ( offlineCollection[index].is_legacy !==  model.is_legacy)
                                {
                                    if(model.is_closed === false)
                                    {
                                        commit('addToCollection', model )
                                    }

                                    updatedOfflineCollection.push( model )
                                }else if(offlineCollection[index].is_closed === false){
    
                                    commit('addToCollection',offlineCollection[index] )

                                    // commit('addToCollection',offlineCollection[index] )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('copied_action_id') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('is_closed_through_latest_evaluation') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('evaluation_type_prompt_uuid') )
                                {
                                    updatedOfflineCollection.push( model )
                                }
                                else if (!offlineCollection[index].hasOwnProperty('answer_description') )
                                {
                                    updatedOfflineCollection.push( model )
                                }

    
                            }else{
                                
                                if(model.is_closed === false)
                                {
                                    commit('addToCollection', model )
                                }

                                updatedOfflineCollection.push( model )
                            }
    
                            //  if ( updatedOfflineCollection.length > 0 ){
                            //      idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                            //  }                      

                    }

                    if ( updatedOfflineCollection.length > 0 ){
                        idbs.saveToOfflineStorage('answers', updatedOfflineCollection )
                        idbs.saveToOfflineStorage('actions', updatedOfflineCollection )
                    }
                    
                    idbs.saveToOfflineStorage('actions', response.data.data)
                    return response.data
                })
                .catch(function( error ){
                    return error
                })
        }catch ( e ){
            throw e
        }finally {
            commit('setLoading', false)
        }

    }
    
}
